import React, { useMemo } from "react";
import { motion } from "framer-motion";
import Img from "gatsby-image";
import cx from "classnames";

import TimeDetail from "../../components/TimeDetail/TimeDetail";
import DetailList from "../../components/DetailList/DetailList";
import IconDetail from "../../components/IconDetail/IconDetail";
import DraftDetail from "../../components/DraftDetail/DraftDetail";
import BlogCategories from "../BlogCategories/BlogCategories";
import BlogCategory from "../BlogCategory/BlogCategory";

import { ReactComponent as Time } from "../../svg/time.svg";
import { stringToHue } from "../../utils/colors";

import "./BlogCard.scss";

const variants = {
    open: {
        scale: 1.0
    },
    focus: {
        scale: 1.03,
        transition: {
            type: "spring",
            damping: 50,
            stiffness: 250
        }
    }
};

const imageVariants = {
    open: {
        scale: 1.1
    },
    focus: {
        scale: 1,
        transition: {
            type: "spring",
            damping: 50,
            stiffness: 250
        }
    }
};

const BlogCard = ({ post }) => {
    const color = useMemo(() => `hsl(${stringToHue(post.id) * 255}, 60%, 80%)`, [post.id]);
    const usesImage = !!post.frontmatter.featuredImage;

    return (
        <motion.a
            initial="open"
            whileHover="focus"
            whileTap={{ scale: 0.95 }}
            variants={variants}
            href={post.fields.path}
            style={{ color: color }}
            className={cx("blog-card", {
                "blog-card--tall": usesImage
            })}>
            <div className="blog-card__body">
                {usesImage && (
                    <div className="blog-card__image">
                        <motion.div
                            variants={imageVariants}>
                            <Img fluid={post.frontmatter.featuredImage.childImageSharp.fluid} />
                        </motion.div>
                    </div>
                )}
                <div className="blog-card__content">
                    <DetailList emphasized noSeperator>
                        { post.frontmatter.draft && (
                            <DraftDetail />
                        )}
                        <TimeDetail time={post.frontmatter.date} />
                        <IconDetail icon={<Time/>}>
                            {post.timeToRead || 0} min read
                        </IconDetail>
                    </DetailList>
                    <h4 className="blog-card__title">
                        { post.frontmatter.title }
                    </h4>
                    <BlogCategories categories={post.frontmatter.categories}/>
                    <p className="blog-card__desc">{post.frontmatter.description}</p>
                </div>
            </div>
        </motion.a>
    );
};

export default BlogCard;
