import React from "react";
import { motion } from "framer-motion";

import BlogCard from "../BlogCard/BlogCard";

import "./BlogGrid.scss";

const BlogGrid = ({ posts }) => (
    <motion.div
        variants={{
            exit: {
                transition: {
                    duration: 1,
                    staggerChildren: 0.02
                }
            }
        }}
        className="blog-grid">
        {posts.map(post => (
            <BlogCard key={post.id} post={post} />
        ))}
    </motion.div>
);

export default BlogGrid;
